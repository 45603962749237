#container-circles {
  bottom: -40px;
}

.oui-camera-container {
  padding-top: 10vh;
  background-color: #000;
  height: 100vh;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .react-html5-camera-photo {
    /* THIS MAKES SURE CAMERA BTN DOESN'T SHIFT WHEN CAMERA LOADS */
    min-height: calc(100vw + 7px);
  }
}

.oui-camera-preview-ctrls {
  color: white;
  text-align: center;
  width: 100%;
  max-width: 420px;
  padding-top: 50px;
  display: inline-block;
}
.oui-camera-preview-ctrls .fa-check-circle {
  color: lightgreen;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.884), 1px -1px 1px rgba(0, 0, 0, 0.884), -1px 1px 1px rgba(0, 0, 0, 0.884), 1px 1px 1px rgba(0, 0, 0, 0.884);
}
.oui-camera-preview-ctrls .fa-undo-alt {
  color: indianred;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.884), 1px -1px 1px rgba(0, 0, 0, 0.884), -1px 1px 1px rgba(0, 0, 0, 0.884), 1px 1px 1px rgba(0, 0, 0, 0.884);
}
.oui-camera-preview-ctrls i {
  margin: 20px 40px 0px 40px;
}

/*# sourceMappingURL=oui-camera.css.map */
