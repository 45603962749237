.img-resize-container {
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 11;
}

.img-resize-mask {
  position: fixed;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10;
}

.img-resize-container > div:last-of-type {
  margin-top: 15px;
}

.img-resize-container > div:last-of-type button {
  width: 120px;
}
.img-resize-container > div:last-of-type button i {
  color: white;
  margin-right: 10px;
}
.img-resize-container > div:last-of-type button:first-of-type {
  margin-right: 20px;
}
